import styles from '../FeaturedContents.module.scss'

const AIAssistedContentOutlines = () => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <h3 className={styles.title}>AI-Assisted Content Outlines</h3>
        <p className={styles.subtitle}>
          Get unstuck from hours of manual research and laborious brief
          outlines. Generate content briefs for your target keyword in 1-click
          and fill up your editorial calendar faster.
        </p>
      </div>
      <div className={styles.image}>
        <video
          src={'/assets/landing/features-images/aiAssistedContentOutlines.mp4'}
          width={542}
          height={380}
          autoPlay
          loop
          muted
        />
      </div>
    </div>
  )
}

export default AIAssistedContentOutlines
