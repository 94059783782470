import styles from '../FeaturedContents.module.scss'

const AIEnhancedMetaDescriptions = () => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <h3 className={styles.title}>AI-Enhanced Meta Descriptions</h3>
        <p className={styles.subtitle}>
          Generate a meta description with a single topic or keyword.
          TopicRanker&apos;s built-in tools build unique, SEO-friendly
          descriptions loved by both humans and search engines.
        </p>
      </div>
      <div className={styles.image}>
        <video
          src={'/assets/landing/features-images/aiEnhancedMetaDescriptions.mp4'}
          width={542}
          height={380}
          autoPlay
          loop
          muted
        />
      </div>
    </div>
  )
}

export default AIEnhancedMetaDescriptions
