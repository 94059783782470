import classNames from 'classnames'
import styles from './HowItWorksTab.module.scss'

const HowItWorksTab = ({
  onClick,
  isSelected,
  children,
  index = 0,
}: {
  index: number
  onClick: (index: number) => void
  isSelected: boolean
  children?: React.ReactNode
}) => {
  return (
    <div
      className={classNames(styles.container, {
        [styles.selected]: isSelected,
      })}
      onClick={() => onClick(index)}
      role="button"
    >
      {children}
    </div>
  )
}

export default HowItWorksTab
