import LowHangingFruit from './LowHangingFruit'
import UnlockingSERPWeaknesses from './UnlockingSERPWeaknesses'
import EmpoweringYourWriters from './EmpoweringYourWriters'
import AIGeneratedTitleSuggestions from './AIGeneratedTitleSuggestions'
import AIAssistedContentOutlines from './AIAssistedContentOutlines'
import AIPoweredBlogPosts from './AIPoweredBlogPosts'
import AIEnhancedMetaDescriptions from './AIEnhancedMetaDescriptions'

const FeatureContents = {
  LowHangingFruit,
  UnlockingSERPWeaknesses,
  EmpoweringYourWriters,
  AIGeneratedTitleSuggestions,
  AIAssistedContentOutlines,
  AIPoweredBlogPosts,
  AIEnhancedMetaDescriptions,
}

export default FeatureContents
