import styles from '../FeaturedContents.module.scss'

const EmpoweringYourWriters = () => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <h3 className={styles.title}>Empowering Your Writers</h3>
        <p className={styles.subtitle}>
          TopicRanker&apos;s range of GPT-4 powered AI tools help you accelerate
          your content marketing program and bring your ideas to the SERPs
          faster.
        </p>
      </div>
      <div className={styles.image}>
        <video
          src={'/assets/landing/features-images/empoweringYourWriters.mp4'}
          width={542}
          height={380}
          autoPlay
          loop
          muted
        />
      </div>
    </div>
  )
}

export default EmpoweringYourWriters
