import styles from '../FeaturedContents.module.scss'

const LowHangingFruit = () => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <h3 className={styles.title}>Low Hanging Fruit</h3>
        <p className={styles.subtitle}>
          Understand specific weaknesses in the search engine results page for
          your keyword. Fix things like thin content, outdated results, and
          bloated websites and leverage it for your content marketing strategy.
        </p>
      </div>
      <div className={styles.image}>
        <video
          src={'/assets/landing/features-images/lowHangingFruit.mp4'}
          width={542}
          height={380}
          autoPlay
          loop
          muted
        />
      </div>
    </div>
  )
}

export default LowHangingFruit
