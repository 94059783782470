import Image from 'next/image'

import styles from './features.module.scss'
import HowItWorksTab from '@components/HowItWorksTab'
import { useEffect, useRef, useState } from 'react'

import FlagIcon from '../../public/assets/landing/features-icons/flag.svg'
import CrossHairIcon from '../../public/assets/landing/features-icons/crosshair.svg'
import MaximizeIcon from '../../public/assets/landing/features-icons/maximize.svg'
import CPUIcon from '../../public/assets/landing/features-icons/cpu.svg'

import FeatureContents from '@components/HowItWorksTab/FeatureContents'
import { useRouter } from 'next/router'
import classNames from 'classnames'

type FeaturesProps = {
  selectedFeature: number
  setSelectedFeature: (index: number) => void
}

type Features = {
  title: string
  icon: any
  content: any
}

const features: Features[] = [
  {
    title: 'Low Hanging Fruit',
    icon: <FlagIcon />,
    content: <FeatureContents.LowHangingFruit />,
  },
  {
    title: 'Unlocking SERP Weaknesses',
    icon: <CrossHairIcon />,
    content: <FeatureContents.UnlockingSERPWeaknesses />,
  },
  {
    title: 'Empowering Your Writers',
    icon: <MaximizeIcon />,
    content: <FeatureContents.EmpoweringYourWriters />,
  },
  {
    title: 'AI-Generated Title Suggestions',
    icon: <CPUIcon />,
    content: <FeatureContents.AIGeneratedTitleSuggestions />,
  },
  {
    title: 'AI-Assisted Content Outlines',
    icon: <CPUIcon />,
    content: <FeatureContents.AIAssistedContentOutlines />,
  },
  {
    title: 'AI-Powered Blog Posts',
    icon: <CPUIcon />,
    content: <FeatureContents.AIPoweredBlogPosts />,
  },
  {
    title: 'AI-Enhanced Meta Descriptions',
    icon: <CPUIcon />,
    content: <FeatureContents.AIEnhancedMetaDescriptions />,
  },
]

const HowItWorks = ({ selectedFeature, setSelectedFeature }: FeaturesProps) => {
  const [isContainerVisible, setIsContainerVisible] = useState(false)
  const containerRef = useRef(null)
  const router = useRouter()

  const [isContentVisible, setIsContentVisible] = useState(true)

  const updateTabParameter = (index: number) => {
    const query = { ...router.query, tab: index }
    router.push(
      {
        pathname: router.pathname,
        query,
      },
      undefined,
      { scroll: false }
    )
  }

  const handleSelectedFeature = (index: number) => {
    setIsContentVisible(false)
    setSelectedFeature(index)
    setIsContentVisible(true)
  }

  useEffect(() => {
    updateTabParameter(selectedFeature)
  }, [selectedFeature])

  useEffect(() => {
    if (router.query?.tab) {
      handleSelectedFeature(Number(router.query.tab))
    }
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsContainerVisible(true)
            if (containerRef.current) observer.unobserve(containerRef.current)
          }
        })
      },
      { threshold: 0.5 }
    )

    if (containerRef.current) {
      observer.observe(containerRef.current)
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current)
      }
    }
  }, [])

  return (
    <section id="features" className={styles.container}>
      <h2
        className={classNames(styles.title, {
          [styles.visible]: isContainerVisible,
        })}
        ref={containerRef}
      >
        How It Works
      </h2>
      <div className={styles.featuresContainer}>
        <div className={styles.featuresOptions}>
          {features.map((features, index) => (
            <HowItWorksTab
              onClick={handleSelectedFeature}
              index={index}
              key={index}
              isSelected={selectedFeature === index}
            >
              {features.icon}
              {features.title}
            </HowItWorksTab>
          ))}
        </div>
        <div
          className={classNames(styles.featuresContent, {
            [styles.featuresContentVisible]: isContentVisible,
          })}
        >
          {features[selectedFeature].content}
        </div>
      </div>
    </section>
  )
}

export default HowItWorks
