import styles from '../FeaturedContents.module.scss'

const AIPoweredBlogPosts = () => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <h3 className={styles.title}>AI-Powered Blog Posts</h3>
        <p className={styles.subtitle}>
          Prevent frustrating revisions and a constant back and forth with your
          writers. Input a keyword, select a content brief, and our content bots
          built on top of GPT-4 put together a blog post that covers all the
          topics in detail.
        </p>
      </div>
      <div className={styles.image}>
        <video
          src={'/assets/landing/features-images/aiPoweredBlogPosts.mp4'}
          width={542}
          height={380}
          autoPlay
          loop
          muted
        />
      </div>
    </div>
  )
}

export default AIPoweredBlogPosts
