import styles from '../FeaturedContents.module.scss'

const UnlockingSERPWeaknesses = () => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <h3 className={styles.title}>Unlocking SERP Weaknesses</h3>
        <p className={styles.subtitle}>
          We give you an itemized breakdown of the top ranking pages for your
          keyword, and expose each weakness as we uncover it.
        </p>
      </div>
      <div className={styles.image}>
        <video
          src={'/assets/landing/features-images/serpWeaknesses.mp4'}
          width={542}
          height={380}
          autoPlay
          loop
          muted
        />
      </div>
    </div>
  )
}

export default UnlockingSERPWeaknesses
