import styles from '../FeaturedContents.module.scss'

const AIGeneratedTitleSuggestions = () => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <h3 className={styles.title}>AI-Generated Title Suggestions</h3>
        <p className={styles.subtitle}>
          Banish writer&apos;s block. Generate SEO-optimized titles for your
          blog post that balance both creativity and click through rates.
        </p>
      </div>
      <div className={styles.image}>
        <video
          src={
            '/assets/landing/features-images/aiGeneratedTitleSuggestions.mp4'
          }
          width={542}
          height={380}
          autoPlay
          loop
          muted
        />
      </div>
    </div>
  )
}

export default AIGeneratedTitleSuggestions
